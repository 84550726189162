<template>
    <div class="good-broad-caster">
        <div style="display: flex; align-items: center">
            <el-radio-group v-model="type" size="medium" class="rank-type" @change="selectRankType">
                <el-radio-button :label="1">7天</el-radio-button>
                <el-radio-button :label="2">15天</el-radio-button>
                <el-radio-button :label="3">30天</el-radio-button>
<!--                <el-radio-button :label="4">90天</el-radio-button>-->
            </el-radio-group>
            <el-date-picker size="medium" v-model="dateTime" @change="toggleDate"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" style="margin: 0 20px"></el-date-picker>
            <el-input size="medium" @change="selectSearch" class="search-input" style="width: 200px"
                      placeholder="请输入达人昵称或抖音号" prefix-icon="el-icon-search" v-model="keyword"></el-input>
        </div>

        <div class="category-list">
            <div class="cate-left">分类：</div>
            <div class="cate-content">
                <div class="cate-item" v-for="(cateItem, cateIndex) in categoryList" @click="chooseCate(cateItem)"
                     :class="{current: category === cateItem.id}" :key="`categoryList_${cateIndex}`">{{cateItem.name}}({{cateItem.value}})</div>
            </div>
        </div>
        <div class="category-list" style="margin-top: 10px">
            <div class="cate-left">粉丝：</div>
            <div class="cate-content">
                <div class="cate-item" v-for="(fansItem, fansIndex) in fansList" @click="chooseFans(fansItem)"
                     :class="{current: follower_count === fansItem.value}" :key="`fansList_${fansIndex}`">{{fansItem.name}}</div>
            </div>
        </div>
        <div class="category-list" style="margin-top: 10px">
            <div class="cate-left">口碑：</div>
            <div class="cate-content">
                <div class="cate-item" v-for="(fansItem, fansIndex) in reputation_filter" @click="chooseReputation(fansItem)"
                     :class="{current: reputation_level === fansItem.id}" :key="`reputation_filter_${fansIndex}`">{{fansItem.name}}({{fansItem.value}})</div>
            </div>
        </div>

        <el-table :data="goodBroadCasterData"
                  :header-cell-style="{fontWeight: 'normal',color: '#333'}" style="width: 100%; margin-top: 10px; min-height: calc(100vh - 500px);">
            <el-table-column label="达人" width="300">
                <template slot-scope="scope">
                    <div class="goods-detail">
                        <div class="goods-img">
                            <img :src="scope.row.avatar" alt="">
                        </div>
                        <div class="goods-info">
                            <span class="user-detail text-overflow">{{ scope.row.nickname }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="label" align="center"></el-table-column>
            <el-table-column label="带货口碑" prop="reputation.score" align="center"></el-table-column>
            <el-table-column label="预估销量(件)" prop="volume" align="center"></el-table-column>
            <el-table-column label="预估销售额" align="center">
                <template slot-scope="scope">{{scope.row.amount.toFixed(2)}}</template>
            </el-table-column>
            <el-table-column label="关联视频" prop="aweme_count" align="center"></el-table-column>
            <el-table-column label="关联直播" prop="room_count" align="center"></el-table-column>
        </el-table>

        <el-pagination v-if="goodBroadCasterData.length > 0"
                class="pages-center" background
                :current-page="listPages.currentPageNum"
                :page-size="listPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="listPages.total"
                @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { rankingSalesGoodsAuthor } from '@/utils/apis.js'
    import * as dayjs from "dayjs";

    export default {
        name: "GoodBroadCaster",
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },

                goodBroadCasterData: [],
                category: '',
                categoryList: [], // 所属行业
                reputation_level: '',
                reputation_filter: [], // 口碑
                follower_count: '',
                fansList: [
                    {
                        value: '',
                        name: '全部',
                    },
                    {
                        value: '-10000',
                        name: '小于1w',
                    },
                    {
                        value: '10000-100000',
                        name: '1w到10w',
                    },
                    {
                        value: '100000-1000000',
                        name: '10w到100w',
                    },
                    {
                        value: '1000000-5000000',
                        name: '100w到500w',
                    },
                    {
                        value: '5000000-10000000',
                        name: '500w到1000w',
                    },
                    {
                        value: '10000000-',
                        name: '大于1000w',
                    },
                ],
                type: 3, // 日期选择
                start_date: dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
                end_date: dayjs().format('YYYY-MM-DD'),
                dateTime: [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
                keyword: '',

                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
          this.getList();
        },
        methods: {
            // 选择日期区间
            toggleDate(val) {
                this.type = ''
                this.start_date = val[0]
                this.end_date = val[1]
                this.listPages.currentPageNum = 1
                this.getList()
            },

            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    size: this.listPages.eachPageNum,
                    product_id: this.$route.query.id,
                    category: this.category,
                    reputation_level: this.reputation_level,
                    follower_count: this.follower_count,

                    keyword: this.keyword,
                }
                if (this.type) {
                    params.type = this.type
                } else {
                    params.start_date = this.start_date
                    params.end_date = this.end_date
                }
                rankingSalesGoodsAuthor(params).then(res => {
                    if (res.code === 200) {
                        this.goodBroadCasterData = res.data.list.list
                        this.listPages.total = res.data.total

                        let categoryTmp = JSON.parse(JSON.stringify(res.data.list.category))
                        let cateTotal = categoryTmp.map(item => item.value).reduce((prev, cur, index, array) => { return prev + cur })
                        let cateItem = {
                            id: '',
                            name: '全部',
                            value: cateTotal,
                            sub: null
                        }
                        categoryTmp.unshift(cateItem)
                        this.categoryList = categoryTmp

                        let reputationTmp = JSON.parse(JSON.stringify(res.data.list.reputation_filter))
                        let reputationTotal = reputationTmp.map(item => item.value).reduce((prev, cur, index, array) => { return prev + cur })
                        let reputationItem = {
                            id: -1,
                            name: '全部',
                            value: reputationTotal,
                        }
                        reputationTmp.unshift(reputationItem)
                        this.reputation_filter = reputationTmp
                    } else {
                        this.$message.warning(res.message)
                    }
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },

            // 选择分类
            chooseCate(item) {
                this.category = item.id
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 选择口碑
            chooseReputation(item) {
                this.reputation_level = item.id
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 选择粉丝数
            chooseFans(item) {
                this.follower_count = item.value
                this.listPages.currentPageNum = 1
                this.getList()
            },

            //选择日榜，周榜，月榜
            selectRankType(val) {
                if (val === 1) {
                    this.dateTime = [dayjs().subtract(6, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                }
                if (val === 2) {
                    this.dateTime = [dayjs().subtract(14, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                }
                if (val === 3) {
                    this.dateTime = [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                }
                // this.dateTime = [dayjs().subtract(val - 1, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                this.listPages.currentPageNum = 1
                this.getList();
            },

            // 搜索
            selectSearch(val) {
                this.keyword = val;
                this.listPages.currentPageNum = 1
                this.getList();
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    .dy-header {
        display: flex;
        align-items: center;
        .rank-type {
            margin-right: 20px;
        }
        .rank-date {
            margin-right: 20px;
        }
        .search-input {
            width: 300px;
        }
    }
    .good-broad-caster {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-detail {
            display: flex;
            align-items: center;
            .goods-img {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                width: 1%;
                flex: 1;
            }
        }
        .goods-video-detail {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #333;
            &:hover {
                color: #1E33E3;
            }
            .goods-img {
                width: 64px;
                height: 89px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                width: calc(100% - 74px);
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                a {
                    color: #1A1A1A;
                }
            }
        }
    }
    .pages-center {
        width: 100%;
        flex: 1 1 0%;
        height: calc(100% - 52px);
    }

    .category-list {
        display: flex;
        margin-top: 20px;
        .cate-left {
            /*width: 70px;*/
            text-align-last: justify;
        }
        .cate-content {
            width: 1%;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            .cate-item {
                padding: 0 8px;
                margin: 0 0 10px 12px;
                border: 1px solid transparent;
                cursor: pointer;
                &.current {
                    border-color: #1E33E3;
                    color: #1E33E3;
                }
            }
        }
    }
</style>