<template>
    <div>
        <div class="hotGood-container">
            <div class="analysis-left">
                <div class="gender-distribution eCharts-item">
                    <span class="title">性别分布</span>
                    <div class="distribution-chart">
                        <div class="chart-icon">
                            <i class="iconfont">&#xe6a6;</i>
                            <i class="iconfont">&#xe6a8;</i>
                        </div>
                        <div class="chart-content">
                            <div class="man" :style="{width: genderAnalysis.female+'%'}"></div>
                            <div class="woman"></div>
                        </div>
                        <div class="legend">
                            <div class="legend-man">
                                <span>男</span>
                                <span>{{(genderAnalysis.female).toFixed(2)}}%</span>
                            </div>
                            <div class="legend-woman">
                                <span>女</span>
                                <span>{{(genderAnalysis.male).toFixed(2)}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eCharts-item">
                    <div class="item-title">
                        <span>年龄分布</span>
                    </div>
                    <div v-show="!showAge" style="text-align: center; color: #999; margin-top: 20px">暂无数据</div>
                    <div class="item-content" id="ageDistribution"></div>
                </div>
            </div>
            <div class="class-list">
                <div class="analysis-right">
                    <div class="big-title">省市排名Top10</div>
                    <div class="flex">
                        <span class="title" :class="{current: areaCurrent === '省份'}" @click="toggleArea('省份')">省份分布</span>
                        <span class="title" :class="{current: areaCurrent === '城市'}" @click="toggleArea('城市')" style="margin-left: 20px">城市分布</span>
                    </div>
                    <div class="area-distribution">
                        <div class="title">
                            <span>名称</span>
                            <span>占比</span>
                        </div>
                        <div class="content">
                            <div v-if="areaDistribution.length === 0" style="text-align: center; color: #999; margin-top: 20px">暂无数据</div>
                            <div class="area-item" v-for="item in areaDistribution">
                                <span class="area-name">{{item.title}}</span>
                                <el-progress :percentage="item.rate" :show-text="false" :stroke-width="12"></el-progress>
                                <span class="percentage">{{item.value}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="big-title" style="margin: 0 20px 20px">视频热门评论Top30</div>
            <el-scrollbar class="comment-box">
                <div class="comment-list" v-if="comment_lists.length > 0">
                    <div class="comment-item" v-for="(commentItem, commentIndex) in comment_lists" :key="`comment_lists_${commentIndex}`">
                        <div class="comment-left">
                            <img :src="commentItem.aweme_cover" alt="">
                        </div>
                        <div class="comment-right">
                            <div class="title text-overflow">{{commentItem.aweme_title}}</div>
                            <div class="bottom">
                                <div class="left">
                                    <i class="iconfont">&#xe85c;</i>
                                    <div class="text">{{commentItem.digg_count}}</div>
                                </div>
                                <div class="center text-overflow-2">{{commentItem.text}}</div>
                                <el-button type="primary" plain size="medium" class="copy-text" @click="clipText(commentItem.text)">复制</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-data" v-else style="height: 100px">暂无数据</div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import { chanmamaGoodsOfficialFans, chanmamaGoodsOfficialComments } from '@/utils/apis.js'
    import ClipboardJS from "clipboard";

    export default {
        name: "GoodAudience",
        data() {
            return {
                //性别分析
                genderAnalysis: {
                    female: 0,
                    male: 0,
                },
                //年龄分布
                ageDistribution: null,
                ageData: [],
                showAge: true,
                //地区分布
                areaDistribution: [],

                areaCurrent: '省份',
                // industryList: [],

                comment_lists: [],
            }
        },
        mounted() {
            this.getInfo()
            this.getComment()
        },
        methods: {
            // 复制
            clipText(val) {
                let clipboard = new ClipboardJS('.copy-text', {
                    text(elem) {
                        return val
                    }
                })
                clipboard.on('success', (e) => {
                    this.$message({
                        type: 'success',
                        message: '复制成功',
                        duration: 1000,
                        onClose: () => {
                            clipboard.destroy()
                        }
                    })
                })
            },
            getComment() {
                let params = {
                    promotion_id: this.$route.query.id,
                    page: 1,
                    size: 30
                }
                chanmamaGoodsOfficialComments(params).then(res => {
                    if (res.code === 200) {
                        this.comment_lists = res.data.list
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            // 获取详情
            getInfo() {
                let params = {
                    promotion_id: this.$route.query.id,
                }
                chanmamaGoodsOfficialFans(params).then(res => {
                    if (res.code === 200) {
                        this.genderAnalysis = res.data.gender

                        let ageData = {
                            xData: [],
                            yData: []
                        }
                        for (let i = 0; i < res.data.age.length; i++) {
                            ageData.xData.push(res.data.age[i].title + '岁');
                            ageData.yData.push(res.data.age[i].rate);
                        }
                        if (res.data.age.length === 0) {
                            this.showAge = false
                        } else {
                            this.showAge = true
                        }

                        let areaList, sum
                        if (this.areaCurrent === '省份') {
                            areaList = res.data.province
                            sum = res.data.province[0].rate
                        } else {
                            areaList = res.data.city
                            sum = res.data.city[0].rate
                        }

                        for (let i in areaList) {
                            let obj = {
                                title: areaList[i].title,
                                rate: Number((areaList[i].rate / sum * 100 ).toFixed(2)),
                                value: Number((areaList[i].rate).toFixed(2))
                            }
                            this.areaDistribution.push(obj);
                        }

                        this.$nextTick(() => {
                            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'))

                            let resizeTimer = null;
                            window.onresize = () => {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(() => {
                                    this.ageDistribution.resize();
                                }, 300)
                            }
                            this.grtAgeDistribution(ageData.xData, ageData.yData)
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            toggleArea(val) {
                this.areaDistribution = []
                this.areaCurrent = val
                this.getInfo()
            },
            // 年龄分布
            grtAgeDistribution(xData, yData){
                this.ageDistribution.setOption({
                    tooltip: {
                        formatter: '{b}：{c}%',
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            formatter: '{value} %'
                        },
                    },
                    grid: {
                        top: 20,
                        left: 40,
                        right: 0,
                        bottom: 40,
                    },
                    series: [{
                        name: '年龄',
                        type: 'bar',
                        barWidth: '15px',
                        data: yData,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: '#34aa7f', // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#46dbab', // 100% 处的颜色
                                    }
                                ], false)
                            },
                        },
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .hotGood-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #EAEAEA;
        }
        .eCharts-item {
            margin-top: 30px;
            padding: 0 20px;
            &:first-child {
                margin-top: 0;
            }
            .item-title {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                .toggle-day {
                    .day {
                        cursor: pointer;
                        color: #999;
                        &.current, &:hover {
                            color: #42D7AB;
                        }
                    }
                }
            }
            .item-content {
                margin-top: 10px;
                width: 100%;
                height: 300px;
            }
        }
    }
    .gender-distribution {
        display: flex;
        flex-direction: column;
        .title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
        }
        .distribution-chart {
            .chart-icon {
                display: flex;
                justify-content: space-between;
                color: #34aa7f;
                margin-top: 10px;
                & i {
                    font-size: 24px;
                }
            }
            .chart-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 20px;
                margin-top: 10px;
                .man {
                    background-color: #34aa7f;
                }
                .woman {
                    flex: 1;
                    background-color: #46dbab;
                }
            }
            .legend {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .legend-man {
                    display: flex;
                    flex-direction: column;
                }
                .legend-woman {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
    .class-list {
        width: 50%;
        height: 1%;
        flex: 1;
        .analysis-right {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0 20px;
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                cursor: pointer;
                &.current {
                    color: #0000FF;
                }
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);
                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }
                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
    }

    .big-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .industry-box {
        display: flex;
        .industry-item {
            width: 1%;
            flex: 1;
            .industry-title {
                .text {
                    line-height: 1;
                    margin-bottom: 10px;
                }
            }
            .industry-column {
                height: 350px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    background: #DDDFEB;
                }
                .column-box {
                    position: absolute;
                    top: 35px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .column {
                    position: absolute;
                    left: 1px;
                    bottom: 0;
                    width: 40px;
                    background: #34aa7f;
                }
            }
            &:nth-of-type(even) {
                .industry-column {
                    .column {
                        background: #46dbab;
                    }
                }
            }
        }
    }

    .no-data {
        height: 300px;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .comment-box {
        margin-bottom: 20px;
        padding-bottom: 25px;
        ::v-deep & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .comment-list {
            padding: 0 20px;
            max-height: 425px;
            display: flex;
            flex-wrap: wrap;
            .comment-item {
                width: calc(50% - 10px);
                display: flex;
                align-items: center;
                box-sizing: border-box;
                margin-bottom: 20px;
                &:nth-of-type(even) {
                    margin-left: 20px;
                }
                .comment-left {
                    width: 63.68421px;
                    height: 88px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .comment-right {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px;
                    .title {
                        color: #999;
                    }
                    .bottom {
                        display: flex;
                        align-items: center;
                        background: #f1f0fe;
                        margin-top: 10px;
                        padding: 10px;
                        .left {
                            text-align: center;
                            .iconfont {
                                color: #e8828b;
                            }
                            .text {
                            }
                        }
                        .center {
                            margin: 0 10px 0 20px;
                            width: 1%;
                            flex: 1;
                        }
                    }
                }

            }
        }
    }
</style>